<!-- Main.svelte -->
<script>
  import { onMount } from 'svelte';
  import { Link } from 'svelte-routing';
  import { db, collection, query, orderBy, getDocs } from './firebase';
  import { isYouTubeUrl, getYouTubeEmbedUrl, getYouTubeShortsEmbedUrl, getYouTubeThumbnailUrl } from './utils';
  import { format } from 'date-fns'; // Import date-fns function

  let events = [];
  let filteredEvents = [];
  let isLoading = true;
  let searchTerm = '';

  // Object to track which videos have been clicked
  let clickedVideos = {};

  // Function to handle video thumbnail click
  function loadVideo(event, videoId) {
    clickedVideos[videoId] = true;
  }

  // Fetch events from the database
  onMount(async () => {
    try {
      const eventsRef = collection(db, 'events');
      const q = query(eventsRef, orderBy('eventDate', 'desc'));
      const querySnapshot = await getDocs(q);
      events = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        eventDate: doc.data().eventDate.toDate(),
        eventDateLocal: doc.data().eventDateLocal ? new Date(doc.data().eventDateLocal) : null,
        eventDateUTC: doc.data().eventDateUTC,
        url: doc.data().url, // Fetching URL from Firestore
        learnMoreUrl: doc.data().learnMoreUrl // Fetching learnMoreUrl from Firestore
      }));
      filteredEvents = events;  // Initialize filteredEvents with all events
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      isLoading = false;
    }
  });

  // Reactive filtering based on search term
  $: {
    filteredEvents = events.filter(event => 
      event.eventName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.locationAddress.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
</script>

<main class="min-h-screen bg-gray-100 p-4">
  <div class="max-w-4xl mx-auto">
    <!-- Search Bar -->
    <div class="search-bar-container mb-6">
      <div class="search-bar">
        <input
          type="text"
          bind:value={searchTerm}
          placeholder="Search events..."
          class="search-input"
        />
      </div>
    </div>

    <!-- Loading Spinner -->
    {#if isLoading}
      <div class="flex justify-center items-center h-64">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    {:else}
      <!-- No Events Found Message -->
      {#if filteredEvents.length === 0}
        <div class="text-center text-gray-500">No events found.</div>
      {:else}
        <!-- Event List -->
        <div class="grid gap-4 md:grid-cols-2">
          {#each filteredEvents as event}
            <div class="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
              <!-- YouTube Video or Image Content -->
              {#if event.url && isYouTubeUrl(event.url)}
                <div class="media-wrapper">
                  <div class="video-container">
                    {#if clickedVideos[event.id]}
                      <!-- Show iframe only after thumbnail is clicked -->
                      {#if event.url.includes("youtube.com/shorts")}
                        <iframe 
                          title="YouTube Shorts Video"
                          width="100%" 
                          height="100%" 
                          src={getYouTubeShortsEmbedUrl(event.url)} 
                          frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                          allowfullscreen>
                        </iframe>
                      {:else}
                        <iframe 
                          title="YouTube Video"
                          width="100%" 
                          height="100%" 
                          src={getYouTubeEmbedUrl(event.url)} 
                          frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                          allowfullscreen>
                        </iframe>
                      {/if}
                    {:else}
                      <!-- Show thumbnail with play button -->
                      <div class="thumbnail-container">
                        <img 
                          src={getYouTubeThumbnailUrl(event.url)} 
                          alt="Video thumbnail" 
                          class="video-thumbnail" 
                          loading="lazy"
                        />
                        <div class="play-button" on:click={() => loadVideo(event, event.id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="48" height="48">
                            <path d="M8 5v14l11-7z" />
                          </svg>
                        </div>
                      </div>
                    {/if}
                  </div>
                </div>
              {:else if event.url}
                <div class="media-wrapper">
                  <img 
                    src={event.url} 
                    alt={event.eventName}
                    class="event-image"
                    loading="lazy"
                    on:error={(e) => {
                      e.target.style.display = 'none';
                      console.error('Image failed to load:', event.url);
                    }}
                  />
                </div>
              {/if}

              <!-- Event Name with Link to Event Details -->
              <Link to={`/event/${event.id}`}>
                <h2 class="text-xl font-semibold mb-2">{event.eventName}</h2>
              </Link>

              <p class="text-gray-600">
                {format(event.eventDate, "EEEE MMMM do yyyy, h:mma")}
              </p>
              <p class="text-gray-600">{event.locationAddress}</p>

              {#if event.imageUrl}
                <img src={event.imageUrl} alt={event.eventName} class="w-full h-auto mt-4" loading="lazy" />
              {/if}

              <!-- Learn More Button that opens the Firestore link -->
              {#if event.learnMoreUrl}
                <div class="mt-4">
                  <a href={event.learnMoreUrl} target="_blank" rel="noopener noreferrer" class="learn-more-button">
                    Learn More
                  </a>
                </div>
              {/if}
            </div>
          {/each}
        </div>
      {/if}
    {/if}
  </div>
</main>

<style>
  .media-wrapper {
    margin-bottom: 15px;
    width: 100%;
    height: auto; /* Let height adjust naturally */
  }
  
  .thumbnail-container {
    position: relative;
    cursor: pointer;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    background-color: #000;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0; /* Remove any margins that might cause gaps */
  }

  .video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures image fills the container */
    object-position: center; /* Centers the image */
    margin: 0; /* Remove any margins */
    padding: 0; /* Remove any padding */
    display: block; /* Removes any inline spacing */
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, transform 0.3s;
    z-index: 2;
  }

  .thumbnail-container:hover .play-button {
    background-color: rgba(255, 0, 0, 0.7);
    transform: translate(-50%, -50%) scale(1.1);
  }

  /* Improved video container styles */
  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    border-radius: 4px;
    background-color: #000;
    margin: 0; /* Remove any margins */
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0; /* Remove any margins */
    padding: 0; /* Remove any padding */
  }
  
  .event-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover; /* Changed from 'contain' to 'cover' */
    border-radius: 4px;
  }

  .learn-more-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #3b82f6;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.2s;
  }

  .learn-more-button:hover {
    background-color: #2563eb;
  }
</style>

