// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {
    getFirestore,
    collection,
    addDoc,
    query,
    orderBy,
    getDocs,
    doc,
    getDoc
} from "firebase/firestore";
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDHW19LdEXCipTYMZiMJaG8oGzvo1KouRs",
    authDomain: "heavenya.firebaseapp.com",
    databaseURL: "https://heavenya.firebaseio.com",
    projectId: "heavenya",
    storageBucket: "heavenya.appspot.com",
    messagingSenderId: "9549130300",
    appId: "1:9549130300:web:8a5663f3ab3e2b9668f139",
    measurementId: "G-0SB1YT02FV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics with error handling
let analytics = null;
try {
    // Check if analytics is supported before initializing
    isSupported().then(supported => {
        if (supported) {
            analytics = getAnalytics(app);
            console.log('Google Analytics initialized successfully');
        } else {
            console.log('Google Analytics not supported in this environment');
        }
    });
} catch (error) {
    console.warn('Analytics failed to initialize:', error);
}

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Export the necessary functions for use in other files
export {
    app,
    analytics,  // This might be null if initialization failed
    auth,
    db,
    collection,
    addDoc,
    query,
    orderBy,
    getDocs,
    doc,
    getDoc,
    storage
};
