<script>
    import { onMount } from 'svelte';
    import { collection, addDoc, serverTimestamp, updateDoc, doc, getDoc } from 'firebase/firestore';
    import { storage } from './firebase.js'; // Correct the import path
    import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
    import { db, auth } from './firebase.js'; // Correct the import path
    import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
    import { navigate } from 'svelte-routing';
    
    let authenticated = false;
    let email = '';
    let password = '';
    let loading = false;
    let currentUser = null;
    let isPromoter = false; // Add state to track if user is a promoter

    onMount(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            authenticated = !!user;
            currentUser = user;
            if (user) {
                eventData.userEmail = user.email;
                eventData.promoterId = user.uid;
                console.log('User authenticated:', user.email);

                // Check if the user is a promoter
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    isPromoter = userDoc.data().promoter === true;
                } else {
                    isPromoter = false;
                }
            }
        });

        return () => unsubscribe();
    });

    async function authenticate() {
        loading = true;
        error = '';
        
        try {
            console.log('Attempting login with:', email);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log('Login successful:', userCredential.user.email);
            authenticated = true;
            eventData.userEmail = userCredential.user.email;
            navigate('/post');
        } catch (e) {
            console.error('Authentication error:', e.code, e.message);
            switch (e.code) {
                case 'auth/invalid-email':
                    error = 'Invalid email format';
                    break;
                case 'auth/user-not-found':
                    error = 'No account found with this email';
                    break;
                case 'auth/wrong-password':
                    error = 'Incorrect password';
                    break;
                default:
                    error = `Login error: ${e.message}`;
            }
        } finally {
            loading = false;
        }
    }

    async function logout() {
        try {
            await signOut(auth);
            authenticated = false;
            currentUser = null;
            isPromoter = false;
            navigate('/');
        } catch (e) {
            console.error('Logout error:', e);
            error = `Error logging out: ${e.message}`;
        }
    }

    let eventData = {
        eventName: '',
        eventType: 'In Person',
        eventDate: '',
        details: '',
        locationAddress: '',
        learnMoreUrl: '',
        url: '',
        price: 'Paid',
        minimumPrice: 0,
        maximumPrice: 0,
        shareText: '',
        userEmail: currentUser?.email || '',
        promoterId: currentUser?.uid || '',
        contentType: 'YouTube Video',
        imageUrl: ''
    };

    let error = '';
    let success = '';
    let isUploading = false;
    let uploadProgress = 0;

    function isValidYouTubeUrl(url) {
        if (!url) return false;
        const ytPattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})$/;
        return ytPattern.test(url);
    }

    function formatAddress(address) {
        return address
            .trim()
            .replace(/\s+/g, ' ')
            .replace(/,\s*/g, ',');
    }

    async function handleSubmit() {
        try {
            if (!auth.currentUser) {
                error = 'You must be logged in to submit an event';
                return;
            }

            if (eventData.contentType === 'YouTube Video' && !isValidYouTubeUrl(eventData.url)) {
                error = 'Please enter a valid YouTube or YouTube Shorts URL';
                return;
            }

            const timestamp = serverTimestamp();
            const selectedDate = new Date(eventData.eventDate);
            const utcDate = selectedDate.toISOString();
            const localDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000)).toISOString();

            const submitData = {
                details: eventData.details,
                eventDate: new Date(utcDate),
                eventDateLocal: localDate,
                eventDateUTC: utcDate,
                eventName: eventData.eventName,
                learnMoreUrl: eventData.learnMoreUrl,
                locationAddress: eventData.locationAddress || '',
                shareText: eventData.shareText,
                eventType: eventData.eventType,
                url: eventData.url,
                userEmail: auth.currentUser.email,
                promoterId: auth.currentUser.uid,
                createdAt: timestamp,
                price: eventData.price,
                minimumPrice: eventData.price === "Paid" ? 
                    (Number.isFinite(eventData.minimumPrice) ? Number(eventData.minimumPrice) : 0) : 0,
                maximumPrice: eventData.price === "Paid" ? 
                    (Number.isFinite(eventData.maximumPrice) ? Number(eventData.maximumPrice) : 0) : 0,
                documentId: '',
                contentType: eventData.contentType
            };

            const docRef = await addDoc(collection(db, 'events'), submitData);
            console.log('Document written with ID: ', docRef.id);
            
            await updateDoc(doc(db, 'events', docRef.id), {
                documentId: docRef.id
            });

            success = 'Event submitted successfully!';
            eventData = {
                eventName: '',
                eventType: 'In Person',
                eventDate: '',
                details: '',
                locationAddress: '',
                learnMoreUrl: '',
                url: '',
                price: 'Paid',
                minimumPrice: 0,
                maximumPrice: 0,
                shareText: '',
                userEmail: currentUser?.email || '',
                promoterId: currentUser?.uid || '',
                contentType: 'YouTube Video',
                imageUrl: ''
            };
        } catch (e) {
            console.error('Submission error details:', e);
            error = `Error submitting event: ${e.message}`;
        }
    }

    async function handleImageUpload(event) {
        const file = event.target.files[0];
        if (!file) return;

        try {
            isUploading = true;
            error = '';

            const storageRef = ref(storage, `event_images/${Date.now()}_${file.name}`);
            await uploadBytes(storageRef, file);
            const downloadUrl = await getDownloadURL(storageRef);
            eventData.url = downloadUrl;
            
            success = 'Image uploaded successfully!';
        } catch (e) {
            console.error('Upload error:', e);
            error = `Error uploading image: ${e.message}`;
        } finally {
            isUploading = false;
        }
    }
</script>

{#if !authenticated}
    <div class="auth-container">
        <div class="auth-card">
            <div class="auth-header">
                <img src="/assets/heavenya.png" alt="Heavenya Logo" class="auth-logo">
                <h2>Welcome Back</h2>
                <p class="auth-subtitle">Sign in to post new events</p>
            </div>
            
            {#if error}
                <div class="error-alert">
                    {error}
                </div>
            {/if}
            
            <form class="auth-form" on:submit|preventDefault={authenticate}>
                <div class="form-group">
                    <input 
                        bind:value={email}
                        type="email" 
                        required 
                        class="form-input"
                        placeholder="Email"
                    />
                </div>
                <div class="form-group">
                    <input 
                        bind:value={password}
                        type="password" 
                        required 
                        class="form-input"
                        placeholder="Password"
                    />
                </div>
                <button type="submit" class="submit-btn" disabled={loading}>
                    {loading ? 'Signing in...' : 'Sign in'}
                </button>
                {#if error}
                    <div class="error-alert" style="margin-top: 10px;">
                        {error}
                    </div>
                {/if}
            </form>
        </div>
    </div>
{:else}
    {#if isPromoter}
        <div class="post-container compact">
            <div class="post-header">
                <h1>Post New Event</h1>
                <p class="post-subtitle">Share your event with the community</p>
                <!-- Commented out logout button for now -->
                <!-- <button on:click={logout} class="logout-btn">Logout</button> -->
            </div>
            
            {#if error}
                <div class="error-alert">
                    {error}
                </div>
            {/if}
            
            {#if success}
                <div class="success-alert">
                    {success}
                </div>
            {/if}

            <form on:submit|preventDefault={handleSubmit} class="post-form">
                <div class="form-grid compact">
                    <!-- Event Details Column -->
                    <div class="form-section top-row">
                        <h3 class="section-title">Event Details</h3>
                        <div class="input-group">
                            <label for="eventName">Event Name</label>
                            <input 
                                id="eventName"
                                bind:value={eventData.eventName}
                                type="text" 
                                required
                                class="form-input"
                            />
                        </div>

                        <div class="input-group">
                            <label for="eventType">Event Type</label>
                            <select 
                                id="eventType"
                                bind:value={eventData.eventType}
                                class="form-select"
                            >
                                <option>In Person</option>
                                <option>Virtual</option>
                            </select>
                        </div>

                        <div class="input-group">
                            <label for="eventDate">Event Date</label>
                            <input 
                                id="eventDate"
                                bind:value={eventData.eventDate}
                                type="datetime-local" 
                                required
                                class="form-input"
                            />
                        </div>
                    </div>

                    <!-- Location & Media Column -->
                    <div class="form-section top-row">
                        <h3 class="section-title">Location & Media</h3>
                        {#if eventData.eventType === 'In Person'}
                            <div class="input-group">
                                <label for="locationAddress">Location Address</label>
                                <input 
                                    id="locationAddress"
                                    bind:value={eventData.locationAddress}
                                    type="text" 
                                    required
                                    class="form-input"
                                />
                            </div>
                        {/if}

                        <div class="input-group">
                            <label for="contentType">Content Type</label>
                            <select 
                                id="contentType"
                                bind:value={eventData.contentType}
                                class="form-select"
                            >
                                <option value="YouTube Video">YouTube Video</option>
                                <option value="Image Upload">Image Upload</option>
                            </select>
                        </div>

                        {#if eventData.contentType === 'YouTube Video'}
                            <div class="input-group">
                                <label for="youtubeUrl">YouTube Video URL</label>
                                <input 
                                    id="youtubeUrl"
                                    bind:value={eventData.url}
                                    type="url" 
                                    required
                                    class="form-input"
                                    placeholder="Enter YouTube video URL"
                                />
                            </div>
                        {:else}
                            <div class="input-group">
                                <label for="imageUpload">Upload Image</label>
                                <input 
                                    id="imageUpload"
                                    type="file" 
                                    accept="image/*"
                                    on:change={handleImageUpload}
                                    class="form-input"
                                    disabled={isUploading}
                                />
                            </div>
                        {/if}
                    </div>

                    <!-- Pricing & Links Column -->
                    <div class="form-section top-row">
                        <h3 class="section-title">Pricing & Links</h3>
                        <div class="input-group">
                            <label for="priceType">Price Type</label>
                            <select 
                                id="priceType"
                                bind:value={eventData.price}
                                class="form-select"
                            >
                                <option>Paid</option>
                                <option>Free</option>
                            </select>
                        </div>

                        {#if eventData.price === 'Paid'}
                            <div class="price-inputs">
                                <div class="input-group">
                                    <label for="minPrice">Minimum Price</label>
                                    <input 
                                        id="minPrice"
                                        bind:value={eventData.minimumPrice}
                                        type="number" 
                                        min="0"
                                        required
                                        class="form-input"
                                    />
                                </div>

                                <div class="input-group">
                                    <label for="maxPrice">Maximum Price</label>
                                    <input 
                                        id="maxPrice"
                                        bind:value={eventData.maximumPrice}
                                        type="number" 
                                        min="0"
                                        required
                                        class="form-input"
                                    />
                                </div>
                            </div>
                        {/if}

                        <div class="input-group">
                            <label for="learnMoreUrl">Learn More URL</label>
                            <input 
                                id="learnMoreUrl"
                                bind:value={eventData.learnMoreUrl}
                                type="url" 
                                required
                                class="form-input"
                                placeholder="Ticket registration link"
                            />
                        </div>
                    </div>

                    <!-- Full width section for details -->
                    <div class="form-section full-width">
                        <div class="input-group">
                            <label for="shareText">Share Text</label>
                            <input 
                                id="shareText"
                                bind:value={eventData.shareText}
                                type="text" 
                                required
                                class="form-input"
                            />
                        </div>

                        <div class="input-group">
                            <label for="eventDetails">Event Details</label>
                            <textarea 
                                id="eventDetails"
                                bind:value={eventData.details}
                                rows="4"
                                required
                                class="form-textarea"
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="form-actions">
                    <button type="submit" class="submit-btn">
                        Submit Event
                    </button>
                </div>
            </form>
        </div>
    {:else}
        <div class="warning-container">
            <h2>Access Denied</h2>
            <p>You are currently not approved as a promoter on Heavenya. If you would like to promote events on Heavenya, please email support@heavenya.org or contact us using the button below.</p>
            <a href="mailto:support@heavenya.org" class="contact-button">
                Contact Support
            </a>
        </div>
    {/if}
{/if}